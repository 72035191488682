import React, { Fragment } from 'react'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer, MDBAnimation } from 'mdbreact'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import CardRelated from '../components/cardRelated'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import AltSection from '../components/columnAlternating'
import Card from '../components/card'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'

library.add(fat)

const CapabilitiesTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const heroImage = data.heroImage
  return (
    <div id="sub-page">
      <Layout>
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.gatsbyImageData.src}  
        />


        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          forealt={post.frontmatter.imageAltText}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
          gradientClass="mask d-flex justify-content-center align-items-center gradient"
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}            
        />

        {post.frontmatter.section && post.frontmatter.section.length > 0 && (
          <section className="bg-white-grey-gradient">
            <MDBContainer>
              {post.frontmatter.section.map((sections, index) => {
                return (
                  <div key={index}>
                    <AltSection
                      key={index}
                      title={sections.title}
                      subtitle={sections.subtitle}
                      description={sections.description}
                      image={sections.image.childImageSharp.gatsbyImageData}
                      imageAltText={sections.alttext}
                      placement={sections.placement}
                    />
                  </div>
                )
              })}
            </MDBContainer>
          </section>
        )}

        {post.frontmatter.helpfulresources && (
          <section className="bg-gray-light">
            <MDBAnimation reveal type="fadeIn" duration="1587ms">
              <MDBContainer>
                <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                  {post.frontmatter.helpfulresources.title}
                </h3>
                {post.frontmatter.helpfulresources.helpfulres && (
                  <MDBRow className="pt-5">
                    <>
                      {post.frontmatter.helpfulresources.helpfulres.map((helpfulres, index) => {
                          return (
                            <Fragment key={index}>
                              <Card
                                collg="4"
                                colmd="6"
                                height="5.5rem"
                                title={helpfulres.title}
                                subtitle={helpfulres.subtitle}
                                description={helpfulres.description}
                                image={helpfulres.image.childImageSharp.gatsbyImageData}
                                imageAltText={helpfulres.alttext}
                                placement={helpfulres.placement}
                                link={helpfulres.link ? helpfulres.link : helpfulres.document.publicURL}
                                descriptionClass="text-card-small"
                              />
                            </Fragment>
                          )
                        }
                      )}
                    </>
                  </MDBRow>
                )}
              </MDBContainer>
            </MDBAnimation>
          </section>
        )}

        {post.frontmatter.related != null &&
          post.frontmatter.related.length > 0 && (
            <section className="bg-white">
              <MDBContainer>
                <MDBRow>
                  <MDBCol md="12" className="mb-5">
                    <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large"> Related items </h3>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  {post.frontmatter.related.map((relate, index) => {
                    return (
                      <CardRelated
                        key={index}
                        collg="4"
                        colmd="6"
                        title={relate.title}
                        image={relate.image.childImageSharp.gatsbyImageData}
                        description={relate.description}
                        linktext={relate.linktext}
                        link={relate.link}
                      />
                    )
                  })}
                </MDBRow>
              </MDBContainer>
            </section>
          )}

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              {post.frontmatter.headline.map((headlines, index) => (
                <MDBCol lg="6" md="12" className="d-flex" key={index}>
                  <div className="headline-tile mt-lg-3 item-headline" key={index} >
                    <div className="headline-icon">
                      <FontAwesomeIcon icon={['fat', headlines.icon]} size="3x" className="fa-fw" />
                    </div>
                    <div className="headline-content">
                      <h3 className="font-alt font-w-700 letter-spacing-1 text-xs-large text-large"> {headlines.title} </h3>
                      <p className="font-w-400 letter-spacing-1 text-xs-medium text-medium"> {headlines.description} </p>
                      <div className="headline-action letter-spacing-1 text-xs-medium text-medium">
                        {headlines.link.substring(0, 4) == "http" ?
                        <a href={headlines.link} className="effect-noline" target="_blank" rel="noopener">
                          {headlines.linktext}
                          <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                        </a>
                        :
                        <Link to={headlines.link} className="effect-noline">
                          {headlines.linktext}
                          <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                        </Link>
                        }
                      </div>
                    </div>
                  </div>
                </MDBCol>
              ))}
            </MDBRow>
          </MDBContainer>
        </section>
      </Layout>
    </div>
  )
}
export default CapabilitiesTemplate

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }     
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "capabilities" } }
    ) {
      html
      frontmatter {
        meta {
          title
          description
        }
        template
        title
        subtitle
        ctatext
        ctaslug        
        openingpara
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        headline {
          title
          icon
          description
          linktext
          link
        }
        section {
          title
          subtitle
          image {
            childImageSharp {
              gatsbyImageData(quality: 90) 
            }
          }
          alttext
          placement
          description
        }
        helpfulresources {
          title
          helpfulres {
            title
            subtitle
            description
            placement
            link
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90) 
              }
            }
            document {
              publicURL
            }           
          }
        }
        related {
          title
          image {
            childImageSharp {
              gatsbyImageData(quality: 90) 
            }
          }
          description
          linktext
          link
        }
      }
    }
  }
`